import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstructionCard } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { NgIf, NgStyle } from '@angular/common';
import { MatCard, MatCardTitle, MatCardContent } from '@angular/material/card';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-marketing-card',
    templateUrl: './marketing-card.component.html',
    styleUrls: ['./marketing-card.component.scss'],
    imports: [NgIf, MatCard, NgStyle, MatCardTitle, MatCardContent, ButtonComponent, TranslocoPipe]
})
export class MarketingCardComponent {
  @Input() marketingCard?: ConstructionCard;
  @Output() onButtonClick = new EventEmitter();
}
