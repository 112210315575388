import { Component, Input } from '@angular/core';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { NgClass, NgIf } from '@angular/common';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { ScaledImgComponent } from '../../../../shared/components/scaled-img/scaled-img.component';

@Component({
    selector: 'packex-filter-card',
    templateUrl: './filter-card.component.html',
    styleUrls: ['./filter-card.component.scss'],
    imports: [MatCard, NgClass, NgIf, IconComponent, ScaledImgComponent, MatCardTitle]
})
export class FilterCardComponent {
  @Input() image = '';
  @Input() title = '';
  @Input() active = false;
  @Input() backgroundColor: string | undefined;

  @Input() firstCard = false;
}
