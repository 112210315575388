<mat-card class="product-card box-shadow" *ngIf="construction">
  <div (click)="createConfiguration()" class="clickable-area">
    <div class="product-configure">
      <packex-label
        class="config-icon"
        icon="config"
        iconColor="white"
        *ngIf="!detailsOpen">
      </packex-label>

      <div class="product-more-information-close position-relative" style="z-index: 10000;"
           *ngIf="detailsOpen"
           (click)="closeDetails($event)">
        <packex-icon icon="close"></packex-icon>
      </div>
    </div>
    <div class="product-image">
      <packex-scaled-img src="{{ construction.imageUrl || 'https://via.placeholder.com/500' }}" [width]=350 [height]=350 alt=""/>
    </div>
    <ng-container>
      <div class="product-details">
        <mat-card-title>
          <h5 class="text-primary-dark">{{ construction.name }}</h5>
        </mat-card-title>
        <mat-card-subtitle class="mt-1 mb-1">
          <span class="caption text-primary-light">{{ construction.catalog }} {{ construction.catalogCode }}</span>
        </mat-card-subtitle>
        <mat-card-content>
          <p class="text-primary-dark">{{ construction.shortDescription }}</p>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
  <div class="product-more-information">
    <packex-button type="tertiary" color="primary-dark" icon="info_circle" class="w-auto align-self-start"
                   *ngIf="!detailsOpen"
                   (click)="breakpointObserverService.isMobile() ? (showDetails()) : openDialog()">
      {{ 'CONSTRUCTION_CARD.MORE_INFORMATION' | transloco }}
    </packex-button>
    <div class="more-information-content" *ngIf="detailsOpen">
      <packex-button type="primary"
                     (click)="createConfiguration()">{{ 'CONSTRUCTION_CARD.CONFIGURE_NOW' | transloco }}</packex-button>
      <div class="product-description mt-3">
        {{ construction?.description }}
      </div>
      <packex-list [attributes]="attributes"></packex-list>
    </div>
  </div>
</mat-card>
