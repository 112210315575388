import { Component, Input } from '@angular/core';
import { IconColor } from '@shared/components/icon/icon.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'packex-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    imports: [IconComponent]
})
export class LabelComponent {
  @Input() label = '';
  @Input() icon = 'edit';
  @Input() iconColor: IconColor = 'white';
}
