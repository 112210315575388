import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactService } from '@app/core/services/contact.service';
import { validateEmail, validatePhoneNumber } from '@app/utils/validators';
import {
  CONSTRUCTION,
  INLAY,
  OTHER,
} from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import Keycloak from 'keycloak-js';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent as ModalComponent_1 } from '../../../../shared/components/modal/modal.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { MatFormField, MatError, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslocoPipe } from '@jsverse/transloco';

export type ContactType = 'inlay' | 'construction' | 'other';

@Component({
  selector: 'packex-request-offer-modal',
  templateUrl: './request-offer-modal.component.html',
  styleUrls: ['./request-offer-modal.component.scss'],
  imports: [
    ModalComponent_1,
    NgIf,
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatError,
    MatHint,
    ButtonComponent,
    NgFor,
    MatCheckbox,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RequestOfferModalComponent
  extends ModalComponent
  implements OnInit
{
  @ViewChild('fileUploader') fileUploaderRef!: ElementRef<HTMLInputElement>;

  private acceptedFileExtensions: string[] = [
    'pdf',
    'cf2',
    'x4',
    'gmt',
    'jpg',
    'jpeg',
  ];

  public form?: FormGroup;
  public files: File[] = [];
  public loggedIn = false;

  public fileUploadValid = true;

  public loading$ = new BehaviorSubject<boolean>(false);
  private keycloak = inject(Keycloak);

  constructor(
    dialogRef: MatDialogRef<RequestOfferModalComponent>,
    private readonly contactService: ContactService,
    private readonly snackbarService: SnackBarService,
    private readonly gtm: GoogleTagService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      type: ContactType;
      configuration?: string;
      redeemCADVoucher?: boolean;
    },
  ) {
    super(dialogRef);
  }

  async ngOnInit() {
    this.loggedIn = this.keycloak.authenticated ?? false;

    this.buildForm();
  }

  get showFileSection(): boolean {
    return [CONSTRUCTION, INLAY].includes(this.dialogData.type);
  }

  get title(): string {
    switch (this.dialogData.type) {
      case INLAY:
        return 'CONSTRUCTIONS.REQUEST_OFFER.TITLE_INLAY';
      case CONSTRUCTION:
        return 'CONSTRUCTIONS.REQUEST_OFFER.TITLE';
      case OTHER:
        return 'CONSTRUCTIONS.REQUEST_OFFER.TITLE_HELP';
    }
  }

  get description(): string {
    switch (this.dialogData.type) {
      case INLAY:
        return 'CONSTRUCTIONS.REQUEST_OFFER.DESCRIPTION_INLAY';
      case CONSTRUCTION: {
        if (this.dialogData.redeemCADVoucher) {
          return 'CONSTRUCTIONS.REQUEST_OFFER.DESCRIPTION_LOYALTY_CAD';
        } else {
          return 'CONSTRUCTIONS.REQUEST_OFFER.DESCRIPTION';
        }
      }
      case OTHER:
        return 'CONSTRUCTIONS.REQUEST_OFFER.DESCRIPTION_HELP';
    }
  }

  get uploadDescription(): string {
    switch (this.dialogData.type) {
      case INLAY:
        return 'CONSTRUCTIONS.REQUEST_OFFER.UPLOAD_DESCRIPTION_INLAY';
      case CONSTRUCTION:
        return 'CONSTRUCTIONS.REQUEST_OFFER.UPLOAD_DESCRIPTION';
      case OTHER:
        return '';
    }
  }

  get buttonText(): string {
    switch (this.dialogData.type) {
      case INLAY:
      case CONSTRUCTION:
        return 'CONSTRUCTIONS.REQUEST_OFFER.SEND_REQUEST';
      case OTHER:
        return 'CONSTRUCTIONS.REQUEST_OFFER.SEND_REQUEST_HELP';
    }
  }

  private buildForm() {
    if (this.loggedIn) {
      this.form = new FormGroup({
        subject: new FormControl('', Validators.required),
        estimatedAmount: new FormControl(''),
        message: new FormControl('', Validators.max(500)),
      });
    } else {
      this.form = new FormGroup({
        company: new FormControl('', Validators.required),
        firstname: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, validateEmail]),
        phone: new FormControl('', [Validators.required, validatePhoneNumber]),
        subject: new FormControl('', Validators.required),
        estimatedAmount: new FormControl(''),
        message: new FormControl('', Validators.max(500)),
        privacy: new FormControl(false, Validators.requiredTrue),
      });
    }
  }

  public chooseFile(): void {
    this.fileUploaderRef.nativeElement.click();
  }

  public onFileSelected(event: Event): void {
    if (event.target) {
      const files = (event.target as HTMLInputElement).files || new FileList();
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);

        this.fileUploadValid = this.checkFileExtension(file?.name);

        if (file && this.fileUploadValid && this.files.length + 1 <= 5) {
          this.files.push.apply(this.files, [file]);
        }
      }
    }
  }

  private checkFileExtension(fileName?: string): boolean {
    if (!fileName) return false;

    return this.acceptedFileExtensions.some((suffix) => {
      return fileName.toLowerCase().endsWith(`.${suffix}`);
    });
  }

  public removeFile(i: number): void {
    this.files.splice(i, 1);
  }

  public convertFileSize(size: number): string {
    const k = size > 0 ? Math.floor(Math.log2(size) / 10) : 0;
    const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'B';
    const count = Math.floor(size / Math.pow(1024, k));

    return `${count}${rank}`;
  }

  public get formIsValid(): boolean {
    return !!this.form?.valid;
  }

  public requestOffer() {
    if (this.form && this.formIsValid) {
      this.loading$.next(true);

      const formData = new FormData();
      formData.set('type', this.dialogData.type);
      formData.set('subject', this.form.get('subject')?.value);

      if (!this.loggedIn) {
        formData.set('company', this.form.get('company')?.value);
        formData.set('firstName', this.form.get('firstname')?.value);
        formData.set('lastName', this.form.get('lastname')?.value);
        formData.set('email', this.form.get('email')?.value);
        formData.set('phone', this.form.get('phone')?.value);
      }

      if (this.form.get('estimatedAmount')?.value) {
        formData.set(
          'estimatedAmount',
          this.form.get('estimatedAmount')?.value,
        );
      }
      if (this.form.get('message')?.value) {
        let message = this.form.get('message')?.value;
        if (this.dialogData.configuration) {
          message += `\n\nConfiguration:\n${this.dialogData.configuration}`;
        }
        formData.set('message', message);
      }

      this.files.forEach((file) => {
        formData.append('construction', file);
      });

      this.contactService.requestOffer(formData).subscribe({
        next: () => {
          this.gtm.push('generate_lead', { category: this.dialogData.type });
          this.loading$.next(false);
          this.snackbarService.showSimpleSuccess('COMMON.REQUEST_SENT');
          this.onConfirm();
        },
        error: () => {
          this.snackbarService.showError({
            message: 'COMMON.REQUEST_SENT_ERROR',
            buttons: [
              {
                label: 'COMMON.REPEAT',
                cb: () => {
                  this.requestOffer();
                },
              },
            ],
          });
          this.onConfirm();
        },
      });
    }
  }
}
