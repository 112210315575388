import { Component } from '@angular/core';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ModalComponent as ModalComponent_1 } from '../modal/modal.component';
import { ButtonComponent } from '../ui/button/button.component';

@Component({
    selector: 'packex-exit-intent-modal',
    templateUrl: './exit-intent-modal.component.html',
    imports: [ModalComponent_1, ButtonComponent, TranslocoPipe]
})
export class ExitIntentModalComponent extends ModalComponent {
  public openMusterboxLink(): void {
    window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
    this.dismiss();
  }
}
