<div class="modal modal-xl">
  <div class="modal-dialog modal-dialog-scrollable modal-xl modal-fullscreen-lg-down">
    <div class="modal-content flex-column flex-lg-row">
      <div class="image-column p-0 m-0 d-flex align-items-center justify-content-center">
        <img class="dialog-image" [src]="dialogData.construction.imageUrl || 'https://via.placeholder.com/500'"
             alt="">
        <packex-button class="close-btn d-flex d-lg-none ms-4" (buttonClick)="dismiss()" icon="close"
                       type="clear-icon"></packex-button>
      </div>

      <div class="d-flex flex-column content-column">
        <div class="modal-body">
          <div class="modal-header p-0">
            <h5 class="mb-1">{{ data.name }}</h5>
            <packex-button class="close-btn d-none d-lg-flex ms-4" (buttonClick)="dismiss()" icon="close"
                           type="clear-icon"></packex-button>
          </div>
          <div
            class="caption text-primary-light mb-3">{{ dialogData.construction.catalog }} {{ dialogData.construction.catalogCode }}</div>

          <p class="text-primary-dark mb-3">{{ data.shortDescription }}</p>

          <p class="text-primary-dark py-3 mb-1 dialog-description">{{ data.description }}</p>

          <packex-list [attributes]="attributes"></packex-list>
        </div>

        <div class="modal-footer box-shadow">
          <packex-button type="primary" *ngIf="!dialogData?.hideButton"
                         class="w-auto"
                         (click)="createConfiguration()">{{ 'CONSTRUCTION_CARD.CONFIGURE_NOW' | transloco }}</packex-button>
        </div>
      </div>
    </div>
  </div>
</div>
