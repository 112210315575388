import { Component, ViewEncapsulation } from '@angular/core';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { FilterCategory } from '@modules/filter/models/filter-category';
import { FilterService } from '@modules/filter/services/filter.service';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-construction-filter-categories',
    templateUrl: './construction-filter-categories.component.html',
    styleUrls: ['./construction-filter-categories.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgFor, NgIf, MatSlideToggle, ButtonComponent, MatCheckbox, AsyncPipe, TranslocoPipe]
})
export class ConstructionFilterCategoriesComponent {
  constructor(
    public readonly filterService: FilterService,
    public breakpointObserverService: BreakpointObserverService,
  ) {}

  public isCategoryOpen(category: FilterCategory): boolean {
    return this.breakpointObserverService.isMobile() ? true : category.active;
  }
}
