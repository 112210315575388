import { Component, EventEmitter, Output } from '@angular/core';
import { FilterItem } from '@modules/filter/models/filter-item';
import { FilterService } from '@modules/filter/services/filter.service';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import { NgFor } from '@angular/common';

const wontShow = ['pillow_box', 'calendar', 'bottle_box'];

@Component({
    selector: 'packex-filter-cards',
    templateUrl: './filter-cards.component.html',
    styleUrls: ['./filter-cards.component.scss'],
    imports: [TranslocoDirective, FilterCardComponent, NgFor, TranslocoPipe]
})
export class FilterCardsComponent {
  @Output() filterApplied = new EventEmitter();
  constructor(public filterService: FilterService) {}

  get filterCards(): FilterItem[] {
    return (this.filterService.getFilterCards()?.items || []).filter(
      (filterCard) => !wontShow.includes(filterCard.type),
    );
  }

  public applyFilter(filterCategory: FilterItem) {
    this.filterService.addFilter(filterCategory);
    this.filterApplied.emit();
  }
}
