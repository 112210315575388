import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigurationsService } from '@app/core/services/configurations.service';
import { ROUTES } from '@app/routes';
import { splitByNewline } from '@app/utils/formHelper';
import { ConstructionCard } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ConfigurationTemplate } from '@shared/models/configuration-template';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { ListComponent } from '../../../../shared/components/ui/list/list.component';
import { NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-construction-modal',
    templateUrl: './construction-modal.component.html',
    styleUrls: ['./construction-modal.component.scss'],
    imports: [ButtonComponent, ListComponent, NgIf, TranslocoPipe]
})
export class ConstructionModalComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<ConstructionModalComponent>,
    private configurationsService: ConfigurationsService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      hideButton: boolean;
      construction: ConstructionCard;
      configurationTemplate: ConfigurationTemplate;
    },
  ) {
    super(dialogRef);
  }

  get data() {
    return (
      this.dialogData.configurationTemplate || this.dialogData.construction
    );
  }

  get attributes(): string[] {
    return splitByNewline(this.data.summary || '');
  }

  public createConfiguration() {
    const constructionId: string = this.dialogData.construction?.id || '';

    this.configurationsService
      .create({
        constructionId,
        templateId: this.dialogData.construction.templateId,
      })
      .subscribe((configuration) => {
        this.onConfirm();
        let url = `${ROUTES.CONFIGURATOR}/${configuration.id}`;

        if (this.dialogData.construction.templateId) {
          url = `${url}?templateId=${this.dialogData.construction.templateId}`;
        }

        this.router.navigateByUrl(url);
      });
  }
}
