import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { ConfigurationsService } from '@app/core/services/configurations.service';
import { ROUTES } from '@app/routes';
import { splitByNewline } from '@app/utils/formHelper';
import { ConstructionModalComponent } from '@modules/constructions/components/construction-modal/construction-modal.component';
import { ConstructionCard } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { Configuration } from '@shared/models/configuration';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { NgIf } from '@angular/common';
import { MatCard, MatCardTitle, MatCardSubtitle, MatCardContent } from '@angular/material/card';
import { LabelComponent } from '../../../../shared/components/ui/label/label.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { ScaledImgComponent } from '../../../../shared/components/scaled-img/scaled-img.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { ListComponent } from '../../../../shared/components/ui/list/list.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-construction-card',
    templateUrl: './construction-card.component.html',
    styleUrls: ['./construction-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, MatCard, LabelComponent, IconComponent, ScaledImgComponent, MatCardTitle, MatCardSubtitle, MatCardContent, ButtonComponent, ListComponent, TranslocoPipe]
})
export class ConstructionCardComponent {
  @Input() construction!: ConstructionCard;

  private _detailsOpen = false;

  get detailsOpen(): boolean {
    return this._detailsOpen;
  }

  @Input() set detailsOpen(value: boolean) {
    this._detailsOpen = value;
  }

  @Output() detailsOpened = new EventEmitter<ConstructionCard>();

  constructor(
    public breakpointObserverService: BreakpointObserverService,
    public dialog: MatDialog,
    private configurationsService: ConfigurationsService,
    private router: Router,
    private readonly gtm: GoogleTagService,
  ) {}

  get attributes(): string[] {
    return splitByNewline(this.construction.summary || '');
  }

  public openDialog() {
    this.dialog
      .open(ConstructionModalComponent, {
        data: {
          construction: this.construction,
        },
      })
      .afterOpened()
      .subscribe(() => {
        this.gtm.push('view_item');
      });
  }

  public createConfiguration(): void {
    const constructionId: string = this.construction.id || '';

    this.configurationsService
      .create({
        constructionId,
        templateId: this.construction.templateId,
      })
      .subscribe(async (configuration: Configuration) => {
        let url = `${ROUTES.CONFIGURATOR}/${configuration.id}`;

        if (this.construction.templateId) {
          url = `${url}?templateId=${this.construction.templateId}`;
        }
        await this.router.navigateByUrl(url);

        await this.gtm.push('configuration', {
          construction_name: configuration.construction.trackingName
            .trim()
            .replace(/\s/g, ''),
          code: configuration.construction.catalogCode,
          catalog: configuration.construction.catalog,
        });
      });
  }

  public closeDetails(event: MouseEvent): void {
    event.stopPropagation();
    this.detailsOpen = false;
  }

  public showDetails(): void {
    if (this.construction) {
      this.detailsOpened.next(this.construction);
    }
  }
}
