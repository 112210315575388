import { Component, ViewEncapsulation } from '@angular/core';
import { FilterService } from '@modules/filter/services/filter.service';
import { NgIf, NgFor } from '@angular/common';
import { MatChip, MatChipRemove } from '@angular/material/chips';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-filter-chip',
    templateUrl: './filter-chip.component.html',
    styleUrls: ['./filter-chip.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgFor, MatChip, MatChipRemove, IconComponent, TranslocoPipe]
})
export class FilterChipComponent {
  constructor(public filterService: FilterService) {}
}
