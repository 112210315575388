<mat-card class="marketing-card box-shadow" *ngIf="marketingCard" [ngStyle]="marketingCard.bgColor ? {
'background-color': marketingCard.bgColor
} : {}">
  <div class="marketing-image">
    <img [src]="marketingCard.imageUrl" alt="">
  </div>
  <div class="marketing-details pt-6 px-3">
    <mat-card-title><h4 class="text-primary-dark">{{ marketingCard.description | transloco }}</h4></mat-card-title>
    <mat-card-content>
      <p class="text-primary-dark mb-3">{{ marketingCard.shortDescription | transloco }}</p>
      <packex-button type="secondary" [color]="marketingCard.color" class="w-auto align-self-start" (buttonClick)="onButtonClick.emit()">
        {{ marketingCard.linkText | transloco }}
      </packex-button>
    </mat-card-content>
  </div>
</mat-card>
