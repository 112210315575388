import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '@modules/filter/services/filter.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ModalComponent as ModalComponent_1 } from '../../../../shared/components/modal/modal.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { ConstructionFilterCategoriesComponent } from '../../../filter/components/construction-filter-categories/construction-filter-categories.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-construction-filter-modal',
    templateUrl: './construction-filter-modal.component.html',
    styleUrls: ['./construction-filter-modal.component.scss'],
    imports: [ModalComponent_1, IconComponent, ConstructionFilterCategoriesComponent, ButtonComponent, TranslocoPipe]
})
export class ConstructionFilterModalComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<ConstructionFilterModalComponent>,
    public filterService: FilterService,
  ) {
    super(dialogRef);
  }
}
